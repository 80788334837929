import PersonIcon from '@material-ui/icons/Person';
import HoroscopeList from './HorosсopeList'
import HoroscopeEdit from './HorosсopeEdit'
import HoroscopeCreate from './HorosсopeCreate'

export default {
  create: HoroscopeCreate,
  edit: HoroscopeEdit,
  list: HoroscopeList,
  icon: PersonIcon,
};