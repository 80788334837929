import * as React from 'react';
import {FC, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  Create,
  SimpleForm,
  DateField,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  DeleteButton,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin';
import {TreeForm} from 'src/resources/tree/TreeForm'

interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Новое Дерево желаний</span>

const TreeCreate: FC<EditProps> = props => {
  return (
    <Create {...props}  title={<Title/>}>
      <TreeForm/>
    </Create>
  );
};

export default TreeCreate;