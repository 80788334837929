import PersonIcon from '@material-ui/icons/Person';
import SelfEsteemList from './SelfEsteemList'
import SelfEsteemEdit from './SelfEsteemEdit'
import SelfEsteemCreate from './SelfEsteemCreate'

export default {
  create: SelfEsteemCreate,
  edit: SelfEsteemEdit,
  list: SelfEsteemList,
  icon: PersonIcon,
};