import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  DateInput,
  ReferenceInput, AutocompleteInput,
} from 'react-admin';

import {MediaInput} from 'src/components/inputs/MediaInput'
import {LangList, PeriodList} from 'src/types'
import {getTranslation} from 'src/utils/translation'
import {RichTextInput} from 'src/components/inputs/RichTextInput'

export const BeautyCalendarForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <BooleanInput source={'published'} label={'Опубликовано'}/>
      <SelectInput source={'type'} label={'Период'} choices={PeriodList} variant={'outlined'} validate={required()} />
      <DateInput source={'startDate'} label={'Стартовая дата'} variant={'outlined'} validate={required()}/>


      <ReferenceArrayInput
        label={'Что можно'}
        source="allowed"
        filterToQuery={searchText => ({ ...(searchText ? { 'translations.body||$contL': searchText} : {}) })}
        format={users => {
          return (users || []).map(i => i?.id ? i.id : i);
        }}
        parse={(i) => {
          return (i || []).map(i => ({id: i}));
        } }
        allowEmpty={false}  reference="tag"
        variant={'outlined'} fullWidth={true}
        sort={{ field: 'translations.body', order: 'ASC' }}
      >
        <AutocompleteArrayInput source="id"  optionText={(record) => `${getTranslation(record)?.body}`}
                                parse={(i) => {
                                  console.log("Parse i1",  i.map(i => ({id: i})))
                                  return i.map(i => ({id: i}));
                                } }
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label={'Что нельзя'}
        source="disallowed"
        filterToQuery={searchText => ({ ...(searchText ? { 'translations.body||$contL': searchText} : {}) })}
        format={users => {
          return (users || []).map(i => i?.id ? i.id : i);
        }}
        parse={(i) => {
          return (i || []).map(i => ({id: i}));
        } }
        allowEmpty={false}  reference="tag"
        variant={'outlined'} fullWidth={true}
        sort={{ field: 'translations.body', order: 'ASC' }}
      >
        <AutocompleteArrayInput source="id"  optionText={(record) => `${getTranslation(record)?.body}`}/>
      </ReferenceArrayInput>
      <ArrayInput source="translations" label={'Контент'}>
        <SimpleFormIterator>

          <SelectInput source="languageCode" label={'Язык'}  variant={'outlined'} choices={LangList} validate={required()} />
          <TextInput source="title" label={'Название'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />

          <RichTextInput source="body" label={'Описание'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>

    </SimpleForm>
  );
};
