import React, {useEffect} from 'react'
import { Admin, Resource, DataProvider } from 'react-admin'

import { AuthProvider } from "src/common/providers/AuthProvider";
import { dataProvider } from "src/common/providers/dataProvider";
// @ts-ignore
import russianMessages from 'ra-language-russian'
import polyglotI18nProvider from 'ra-i18n-polyglot'

import { theme } from 'src/style'
import administrators from 'src/resources/administrators'
import affirmation from 'src/resources/affirmation'
import lifeChange from 'src/resources/life-change'
import prediction from 'src/resources/prediction'
import selfEsteem from 'src/resources/self-esteem'
import tree from 'src/resources/tree'
import typedAsset from 'src/resources/typed-asset'
import lifeCycle from 'src/resources/life-cycle'
import karma from 'src/resources/karma'
import destinyNumber from 'src/resources/destiny-number'
import planner from 'src/resources/planner'
import relationship from 'src/resources/relationship'
import meditation from 'src/resources/meditation'
import meditationCategory from 'src/resources/meditation-category'
import coloring from 'src/resources/coloring'
import horoscope from 'src/resources/horoscope'
import calendar from 'src/resources/calendar'
import beautyCalendar from 'src/resources/beauty-calendar'
import tag from 'src/resources/tag'
import matrix from 'src/resources/matrix'

import media from 'src/resources/media'
import faq from 'src/resources/faq'
const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')

const App = () => {
  return (
    <Admin
      theme={theme}
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      authProvider={AuthProvider}
    >
      <Resource name="admin" {...administrators} options={{label: 'Админы'}}/>
      <Resource name="affirmation" {...affirmation} options={{label: 'Аффирмации'}}/>
      <Resource name="lifeChange" {...lifeChange} options={{label: 'Изменение жизни'}}/>
      <Resource name="prediction" {...prediction} options={{label: 'Предсказания'}}/>
      <Resource name="tree" {...tree} options={{label: 'Дерево жизни'}}/>
      <Resource name="selfEsteem" {...selfEsteem} options={{label: 'Самооценки'}}/>

      <Resource name="lifeCycle" {...lifeCycle} options={{label: 'Циклы жизни'}}/>
      <Resource name="karma" {...karma} options={{label: 'Карма'}}/>
      <Resource name="destinyNumber" {...destinyNumber} options={{label: 'Числа судьбы'}}/>
      <Resource name="planner" {...planner} options={{label: 'График дел'}}/>
      <Resource name="relationship" {...relationship} options={{label: 'Отношения'}}/>
      <Resource name="meditation" {...meditation} options={{label: 'Медитации'}}/>
      <Resource name="category" {...meditationCategory} options={{label: 'Категории'}}/>
      <Resource name="coloring" {...coloring} options={{label: 'Раскраски'}}/>
      <Resource name="horoscope" {...horoscope} options={{label: 'Гороскопы'}}/>
      <Resource name="calendar" {...calendar} options={{label: 'Календарь'}}/>
      <Resource name="beautyCalendar" {...beautyCalendar} options={{label: 'Календарь красоты'}}/>
      <Resource name="matrix" {...matrix} options={{label: 'Матрицы'}}/>

      <Resource name="asset" {...media} options={{label: 'Файлы'}}/>

      <Resource name="typedAsset" {...typedAsset} options={{label: 'Медиа'}}/>
      <Resource name="tag" {...tag} options={{label: 'Теги'}}/>
      <Resource name="faq" {...faq} options={{label: 'Вопросы и ответы'}}/>



    </Admin>
  )
}

export default App
