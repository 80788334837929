import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ListProps,
  Datagrid,
  FunctionField, SelectField,
  BooleanInput, DateField, SimpleForm, SelectInput, required
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {CategoryTypeList} from 'src/types'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'type': 'Название',
      'name': 'Название',
      'published': 'Опубликовано',
      'isNew': 'Новая',
      'createdAt': 'Создано',

    },
    data:  posts.map(item => ({
      ...item,
      name: getTranslation(item)?.name,
      type: CategoryTypeList.find(i => i.id === item.type)?.name || '',
      published: item.published ? 'Да' : '',
      isNew: item.isNew ? 'Новая' : '',
      createdAt:  formatCsvDate(item.createdAt),
      updatedAt:  formatCsvDate(item.updatedAt),
      publishedAt:  formatCsvDate(item.publishedAt),
    })),
    fileName: 'MeditationCategory'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />

    <TextInput source="translations.name||$contL" label={'Поиск'}  variant={'outlined'}  alwaysOn={true} resettable={true}/>
    <SelectInput source="type" label={'Тип'}  variant={'outlined'} choices={CategoryTypeList}  alwaysOn={true} resettable={true}/>
    <BooleanInput source={'isNew'} label={'Новая'}/>
    <BooleanInput source={'published'} label={'Опубликовано'}/>

  </Filter>
)

const MeditationCategoryList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Категории"
      empty={<EmptyList title={'Нет аффирмаций'} description={'Вы можете добавить категории'} buttonText={'Добавить категорию'}/>}
    >
    <Datagrid rowClick={'edit'}>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <VisibilityField source={'published'} label={'Опубликовано'}/>
      <FunctionField source={'isNew'} label={'Новая'} render={i => i.isNew ? 'Новая' : ''} />
      <SelectField source={'type'} label={'Тип'} choices={CategoryTypeList} />
      <TransTextField source="name" label={'Название'}/>
      <DateField source="createdAt" label={'Создано'} showTime={true} />
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default MeditationCategoryList;
