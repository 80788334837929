import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import {MediaInput} from 'src/components/inputs/MediaInput'
import {getTranslation} from 'src/utils/translation'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import {LangList} from "src/types";

export const MeditationForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <BooleanInput source={'published'} label={'Опубликовано'}/>
      <BooleanInput source={'isNew'} label={'Новая'}/>
      <MediaInput source={'preview'} label={'Картинка Превью'} helperText={'Выводить в списке медитаций'}/>
      <MediaInput source={'image'} label={'Картинка'}  helperText={'Выводить на экране медитации'}/>
      <MediaInput source={'sound'} label={'Музыка'} accept={['audio/mpeg']}/>
      <ReferenceInput label="Категория" source="category.id" reference="category" variant={'outlined'} validate={required()}
                      filterToQuery={searchText => ({ ...(searchText ? { 'translations.name||$contL||translations.languageCode||$eq': searchText} : {}) })}
                      sort={{ field: 'translations.name', order: 'ASC' }}
                      filter={{type: 'meditation'}}
                      fullWidth={true} perPage={100}>
        <AutocompleteInput optionText={(record) => `${getTranslation(record)?.name}`}/>
      </ReferenceInput>
      <ArrayInput source="translations" label={'Контент'}>
        <SimpleFormIterator>
          <SelectInput source="languageCode" label={'Язык'}  variant={'outlined'} choices={LangList} validate={required()}/>
          <TextInput source="name" label={'Название'} multiline={true} variant={'outlined'} validate={required()}/>
          <RichTextInput source="body" label={'Описание'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>

    </SimpleForm>
  );
};
