import PersonIcon from '@material-ui/icons/Person';
import ColoringList from './ColoringList'
import ColoringEdit from './ColoringEdit'
import ColoringCreate from './ColoringCreate'

export default {
  create: ColoringCreate,
  edit: ColoringEdit,
  list: ColoringList,
  icon: PersonIcon,
};