import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  DateInput,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  Filter,
  ReferenceInput, AutocompleteInput,
} from 'react-admin';

import {MediaInput} from 'src/components/inputs/MediaInput'
import {LangList, MoonPhaseList, PeriodListShort} from 'src/types'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import {getTranslation} from 'src/utils/translation'

export const CalendarForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <BooleanInput source={'published'} label={'Опубликовано'}/>
      <SelectInput source={'moonPhase'} label={'Фаза луны'} choices={MoonPhaseList} variant={'outlined'} validate={required()} />

      <SelectInput source={'type'} label={'Период'} choices={PeriodListShort} variant={'outlined'} validate={required()} />
      <DateInput source="startDate" label={'Дата'}  variant={'outlined'} validate={required()} />
      <ReferenceInput label="Что можно" source={'advice.id'}
                      allowEmpty={false}  resettable={true} reference="tag"
                      variant={'outlined'} fullWidth={true}
                      filterToQuery={searchText => ({ ...(searchText ? { 'translations.body||$contL': searchText} : {}) })}
                      sort={{ field: 'translations.body', order: 'ASC' }}
                      filter={{type: 'calendar'}}
                    perPage={100}>
        <AutocompleteInput optionText={(record) => `${getTranslation(record)?.body}`}/>
      </ReferenceInput>

      <ArrayInput source="translations" label={'Контент'}>
        <SimpleFormIterator>
          <SelectInput source="languageCode" label={'Язык'}  variant={'outlined'} choices={LangList} validate={required()} />

          <TextInput source="title" label={'Название'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />
          <RichTextInput source="body" label={'Описание'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>

    </SimpleForm>
  );
};
