import PersonIcon from '@material-ui/icons/Person';
import PlannerList from './PlannerList'
import PlannerEdit from './PlannerEdit'
import PlannerCreate from './PlannerCreate'

export default {
  create: PlannerCreate,
  edit: PlannerEdit,
  list: PlannerList,
  icon: PersonIcon,
};