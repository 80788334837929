import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ListProps,
  Datagrid,
  BooleanInput, DateField, SelectField, SelectInput, required, DateInput, SimpleForm
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import {PeriodHoroscopeList, PeriodList, SignTypes} from 'src/types'
import VisibilityField from 'src/components/fields/VisibilityField'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'sign': 'Знак зодиака',
      'type': 'Период',
      'startDate': 'Стартовая дата',
      'title': 'Заголовок',
      'body': 'Описание',
      'createdAt': 'Создано',

    },
    data:  posts.map(item => ({
      ...item,
      sign: SignTypes.find(i => i.id === item.sign)?.name || '',
      type: PeriodHoroscopeList.find(i => i.id === item.type)?.name || '',
      startDate:  formatCsvDate(item.startDate, true),
      createdAt:  formatCsvDate(item.createdAt),
      updatedAt:  formatCsvDate(item.updatedAt),
      publishedAt:  formatCsvDate(item.publishedAt),
    })),
    fileName: 'Horoscope'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <TextInput source="translations.title||$contL" label={'Заголовок'}  variant={'outlined'}  alwaysOn={true} resettable={true}/>

    <TextInput source="translations.body||$contL" label={'Описание'}  variant={'outlined'} />
    <BooleanInput source={'published'} label={'Опубликовано'}/>
    <SelectInput source={'sign'} label={'Знак гороскопа'} choices={SignTypes} variant={'outlined'}  />
    <SelectInput source={'type'} label={'Период'} choices={PeriodHoroscopeList} variant={'outlined'}  />
    <DateInput source={'startDate'} label={'Стартовая дата'}/>


  </Filter>
)

const HoroscopeList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Гороскопы"
      empty={<EmptyList title={'Нет Гороскопов'} description={'Вы можете добавить Гороскоп'} buttonText={'Добавить Гороскоп'}/>}
    >
    <Datagrid rowClick={'edit'}>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <VisibilityField source={'published'} label={'Опубликовано'}/>
      <SelectField source={'sign'} label={'Знак гороскопа'} choices={SignTypes}/>
      <SelectField source={'type'} label={'Период'} choices={PeriodHoroscopeList}/>
      <DateField source="startDate" label={'Стартовая дата'} />
      <TransTextField source="title" label={'Заголовок'}/>

      <TransTextField source="body" label={'Описание'}/>
      <DateField source="createdAt" label={'Создано'}showTime={true} />
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default HoroscopeList;
