import { ReduxState } from 'ra-core'
export interface IRequestData {
  url: string
  method?: 'POST' | 'PUT' | 'DELETE' | 'GET'
  data?: any
  token?: string
  host?: string
}

export interface IResponse {
  data: any
  err: any
}

export interface BaseAction {
  type: string
  payload: any
}

export type ThemeName = 'light' | 'dark'

export interface AppState extends ReduxState {

}

export interface IUser {
  id: number
  name: string
}



export const SignTypes = [
  {id: 'Aries', name: 'Aries'},
  {id: 'Taurus', name: 'Taurus'},
  {id: 'Gemini', name: 'Gemini'},
  {id: 'Cancer', name: 'Cancer'},
  {id: 'Leo', name: 'Leo'},
  {id: 'Virgo', name: 'Virgo'},
  {id: 'Libra', name: 'Libra'},
  {id: 'Scorpio', name: 'Scorpio'},
  {id: 'Sagittarius', name: 'Sagittarius'},
  {id: 'Capricorn', name: 'Capricorn'},
  {id: 'Aquarius', name: 'Aquarius'},
  {id: 'Pisces', name: 'Pisces'},
]

export const PlanetSignTypes = [
  {id: 'WhiteMoon', name: 'WhiteMoon'},
  {id: 'BlackMoon', name: 'BlackMoon'},
  {id: 'Sun', name: 'Sun'},
  {id: 'Jupiter', name: 'Jupiter'},
  {id: 'KarmicKnots', name: 'KarmicKnots'},
  {id: 'Saturn', name: 'Saturn'},
  {id: 'Chiron', name: 'Chiron'},
  {id: 'Uranus', name: 'Uranus'},
  {id: 'Venus', name: 'Venus'},
]
export const MonthList = [
  {id: 1, name: 'Январь'},
  {id: 2, name: 'Февраль'},
  {id: 3, name: 'Март'},
  {id: 4, name: 'Апрель'},
  {id: 5, name: 'Май'},
  {id: 6, name: 'Июнь'},
  {id: 7, name: 'Июль'},
  {id: 8, name: 'Август'},
  {id: 9, name: 'Сентябрь'},
  {id: 10, name: 'Октябрь'},
  {id: 11, name: 'Ноябрь'},
  {id: 12, name: 'Декабрь'},
]

export const PeriodList = [
  {id: 'daily', name: 'Дневной'},
  {id: 'weekly', name: 'Недельный'},
  {id: 'monthly', name: 'Месячный'}
]
export const PeriodHoroscopeList = [
  {id: 'daily', name: 'Дневной'},
  {id: 'weekly', name: 'Недельный'},
  {id: 'monthly', name: 'Месячный'},
  {id: 'yearly', name: 'Годовой'},
]
export const PeriodListShort = [
  {id: 'daily', name: 'Дневной'},
  {id: 'monthly', name: 'Месячный'}
]
export const TagTypeList = [
  {id: 'beautyCalendar', name: 'Календарь красоты'},
  {id: 'calendar', name: 'Календарь'},
]

export const CategoryTypeList = [
  {id: 'meditation', name: 'Медитация'},
  {id: 'coloring', name: 'Раскраски'},
]

export const MoonPhaseList = [
  {id: 1, name: 'Новолуние (1)'},
  {id: 2, name: 'Молодая луна (2)'},
  {id: 3, name: 'Первая четверть (3)'},
  {id: 4, name: 'Прибывающая луна (4)'},
  {id: 5, name: 'Полнолуние (5)'},
  {id: 6, name: 'Убывающая луна (6)'},
  {id: 7, name: 'Последняя четверть (7)'},
  {id: 8, name: 'Старая луна (8)'},
  {id: 9, name: '9'},
  {id: 10, name: '10'},
  {id: 11, name: '11'},
  {id: 12, name: '12'},
]

export const TypedAssetTypes = [
  {id: 'melody', name: 'Мелодия'},
  {id: 'wishcardPicture', name: 'Картинка карты желаний'},
  {id: 'background', name: 'Фон'}
]
export const AdminRoles = [
  {id: 'admin', name: 'Администратор'},
  {id: 'manager', name: 'Контент менеджер'}
]

/*
English, French, German, Italian, Japanese, Portuguese, Simplified Chinese, Spanish, Korean, Arabic
 */
export const LangList = [
  {id: 'ru', name: 'Русский'},
  {id: 'en', name: 'Английский'},
  {id: 'fr', name: 'French'},
  {id: 'de', name: 'German'},
  {id: 'it', name: 'Italian'},
  {id: 'ja', name: 'Japanese'},
  {id: 'pt', name: 'Portuguese'},
  {id: 'zh', name: 'Simplified Chinese'},
  {id: 'es', name: 'Spanish'},
  {id: 'ko', name: 'Korean'},
  {id: 'ar', name: 'Arabic'},
]
