import * as React from 'react';
import { FC, ReactElement } from 'react';
import { linkToRecord, Record } from 'ra-core';

import {
 FunctionField,
} from 'react-admin';
import {getTranslation} from 'src/utils/translation'
interface TransTextFieldProps {
  basePath?: string;
  record?: Record;
  source?: string
  label?: string,
}
const TransTextField: FC<TransTextFieldProps> = (props) => {
  const {
    basePath = '',
      record,
      source,
  ...rest
  } = props;
  const translation = getTranslation(record);
  const translationValue = translation ? translation[source] : '';
  return <FunctionField {...props} source={source} render={() => translationValue}/>
}



export default TransTextField
