import PersonIcon from '@material-ui/icons/Person';
import PredictionList from './PredictionList'
import PredictionEdit from './PredictionEdit'
import PredictionCreate from './PredictionCreate'

export default {
  create: PredictionCreate,
  edit: PredictionEdit,
  list: PredictionList,
  icon: PersonIcon,
};