import PersonIcon from '@material-ui/icons/Person';
import TagList from './TagList'
import TagEdit from './TagEdit'
import TagCreate from './TagCreate'

export default {
  create: TagCreate,
  edit: TagEdit,
  list: TagList,
  icon: PersonIcon,
};