import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ListProps,
  Datagrid,
  BooleanInput, DateField, SelectInput, required, SimpleForm, SelectField
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import {SignTypes, TagTypeList} from 'src/types'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'type': 'Тип',
      'body': 'Описание',
      'createdAt': 'Создано',

    },
    data:  posts.map(item => ({
      ...item,
      type: TagTypeList.find(i => i.id === item.type)?.name || '',
      body: getTranslation(item)?.body,
      createdAt:  formatCsvDate(item.createdAt),
      updatedAt:  formatCsvDate(item.updatedAt),
      publishedAt:  formatCsvDate(item.publishedAt),
    })),
    fileName: 'Tag'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <TextInput source="translations.body||$contL" label={'Поиск'}  variant={'outlined'}  alwaysOn={true} resettable={true}/>
    <SelectInput source={'type'} label={'Тип (для чего используется)'} choices={TagTypeList} variant={'outlined'}  alwaysOn={true} resettable={true} />

  </Filter>
)

const TagList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Теги"
      empty={<EmptyList title={'Нет тегов'} description={'Вы можете добавить Тег'} buttonText={'Добавить Тег'}/>}
    >
    <Datagrid rowClick={'edit'}>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <SelectField source={'type'} label={'Тип'} choices={TagTypeList}/>
      <TransTextField source="body" label={'Описание'}/>
      <DateField source="createdAt" label={'Создано'}showTime={true} />
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default TagList;
