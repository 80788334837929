import PersonIcon from '@material-ui/icons/Person';
import KarmaList from './KarmaList'
import KarmaEdit from './KarmaEdit'
import KarmaCreate from './KarmaCreate'

export default {
  create: KarmaCreate,
  edit: KarmaEdit,
  list: KarmaList,
  icon: PersonIcon,
};