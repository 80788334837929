import PersonIcon from '@material-ui/icons/Person';
import TypedAssetList from 'src/resources/typed-asset/TypedAssetList'
import TypedAssetEdit from 'src/resources/typed-asset/TypedAssetEdit'
import TypedAssetCreate from 'src/resources/typed-asset/TypedAssetCreate'

export default {
  create: TypedAssetCreate,
  edit: TypedAssetEdit,
  list: TypedAssetList,
  icon: PersonIcon,
};