import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  TextInput, useDataProvider, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, ArrayInput,
} from 'react-admin';

import {MediaInput} from 'src/components/inputs/MediaInput'
import {getTranslation} from 'src/utils/translation'
import {CategoryTypeList, LangList} from 'src/types'

export const MeditationCategoryForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <BooleanInput source={'published'} label={'Опубликовано'}/>
      <MediaInput source={'image'} label={'Картинка'}/>
      <BooleanInput source={'isNew'} label={'Новая'}/>
      <SelectInput source="type" label={'Тип'}  variant={'outlined'} choices={CategoryTypeList} validate={required()}/>
      <ArrayInput source="translations" label={'Контент'}>
        <SimpleFormIterator>
          <SelectInput source="languageCode" label={'Язык'}  variant={'outlined'} choices={LangList} validate={required()}/>
          <TextInput source="name" label={'Название'} multiline={true} variant={'outlined'} validate={required()}/>
        </SimpleFormIterator>
      </ArrayInput>

    </SimpleForm>
  );
};
