import PersonIcon from '@material-ui/icons/Person';
import BeautyCalendarList from 'src/resources/beauty-calendar/BeautyCalendarList'
import BeautyCalendarEdit from 'src/resources/beauty-calendar/BeautyCalendarEdit'
import BeautyCalendarCreate from 'src/resources/beauty-calendar/BeautyCalendarCreate'

export default {
  create: BeautyCalendarCreate,
  edit: BeautyCalendarEdit,
  list: BeautyCalendarList,
  icon: PersonIcon,
};