import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
    BooleanInput,
    Datagrid,
    DateField,
    DateInput,
    SimpleForm,
    SimpleFormIterator,
    Edit,
    EditButton,
    EditProps,
    FormTab,
    required,
    SelectInput,
    TextInput, useDataProvider, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, ArrayInput, NumberInput,
} from 'react-admin';

import {MediaInput} from 'src/components/inputs/MediaInput'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import {LangList} from "src/types";


export const MatrixForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <BooleanInput source={'published'} label={'Опубликовано'}/>
        <NumberInput source={'digit'} label={'Цифры'}/>
        <NumberInput source={'count'} label={'Кол-во'}/>
      <ArrayInput source="translations" label={'Контент'}>
        <SimpleFormIterator>
          <SelectInput source="languageCode" label={'Язык'}  variant={'outlined'} choices={LangList} validate={required()} />
          <RichTextInput source="body" label={'Описание'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};
