import PersonIcon from '@material-ui/icons/Person';
import AffirmationList from './AffirmationList'
import AffirmationEdit from './AffirmationEdit'
import AffirmationCreate from './AffirmationCreate'

export default {
  create: AffirmationCreate,
  edit: AffirmationEdit,
  list: AffirmationList,
  icon: PersonIcon,
};