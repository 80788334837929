import React, {
  FunctionComponent, useState,
} from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { useInput, useTranslate, InputProps } from 'ra-core'
import {Button} from '@material-ui/core'
import ModalMedia from 'src/components/ModalMedia'
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import {getMediaPath} from 'src/utils/media'
import {Labeled} from 'ra-ui-materialui'
import sanitizeRestProps from 'src/components/sanitizeRestProps'

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: '8px'
    },
    mainImage: {
      maxHeight: '200px'
    },

    previews: {
      display: 'flex',
      margin: '15px 0px 0px 0px'
    },
    previewCard: {
      margin: '3px',
      cursor: 'pointer'
    },
    preview: {
      maxHeight: '220px',
    },
    previewCardActive: {
      border: '2px solid red'
    }
  }),
  { name: 'RaFileInput' },
)

export interface FileInputProps {
  accept?: string[]
  isMulti?: boolean
  showSetMain?: boolean

}

export interface FileInputOptions {
  inputProps?: any
  onRemove?: Function
}

export const MediaInput: FunctionComponent<
  FileInputProps & InputProps<FileInputOptions>
  > = props => {
  const {
    accept,
    children,
    parse,
    maxSize,

    placeholder,
    resource,
    source,
    validate,
    ...rest
  } = props

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);


  const classes = useStyles(props)
  console.log("AccetInput", props);
  const {
    input: { onChange, value, ...inputProps },
    meta,
  } = useInput(
    {
    source,
    validate,
    ...rest,
  })


  const files = props.isMulti ? (value.assets ? (Array.isArray(value.assets) ? value.assets : [value.assets]) : []) : [...(value ? [value] : [])]
  const defaultAsset = props.isMulti ?  files.find(file => file.id === value?.featuredAssetId) : value;
  console.log("defaultAsset", value)
  const [activeImage, setActiveImage] = useState(files.length > 0 ? files[0] : null)
  const [modalOpened, setModalOpened] = useState(false)
  const [editAsset, setEditAsset] = useState(null)
  const handleAddAsset = (record) => {
    console.log("Select",files, record)
    if(!props.isMulti){
      onChange(record);
      setModalOpened(false)
      return;
    }
    if(!files.find(file => file.source === record.source)) {
      if(props.isMulti){
        onChange({...value, assets: [...files, record], ...(props.showSetMain && files.length === 0 ? {featuredAssetId: record.id} : {})})

      }else{
        onChange(files[0])

      }
    }
    setModalOpened(false)
  }
  const handleSelect = (e, record) => {
    setAnchorEl(e.currentTarget);
    setActiveImage(record);
  }
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleDelete = (record) => {
    if(!props.isMulti){
      onChange(null);
    }else {
      const newAssets = value.assets.filter(file => file.id !== record.id);
      onChange({
        ...value, assets: newAssets, ...(
          record.id === value.featuredAssetId ? {featuredAssetId: newAssets[0]?.id} : {}
        )
      })
    }

    setAnchorEl(null);
  };
  const handleSetFeatured = (record) => {
    onChange({...value, featuredAssetId: record.id})

    setAnchorEl(null);
  };
  const handleShow= (record) => {

    setAnchorEl(null);
    setEditAsset(record);
  };
  const isImage = (source) => {
    const parts = source.split('.');
    const ext = parts[parts.length - 1]
      .replace('.', '')
      .toLowerCase();
    if (['jpg', 'png', 'gif', 'jpeg'].includes(ext)) {
      return true
    }
  }

  return (
    <div>
    <Labeled
      label={props.label}
      className={classnames(classes.root)}
      source={source}
      resource={resource}
      meta={meta}
      {...sanitizeRestProps(rest)}
    >
      <div>

      {defaultAsset && <div  onClick={(e) => handleSelect(e, defaultAsset)}>
        {isImage(defaultAsset?.source) ?
          <img className={classes.mainImage} src={`${getMediaPath(defaultAsset?.source)}?preset=small`}/>
          : <a href={`${getMediaPath(defaultAsset?.source)}`}>{defaultAsset.name}</a>}
      </div>}
      {!props.isMulti && defaultAsset && <Menu
        id={`asset-menu-${defaultAsset}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {/*<MenuItem onClick={() => handleShow(defaultAsset)}>Редактировать</MenuItem>*/}
        <MenuItem onClick={() => handleDelete(defaultAsset)}>Удалить</MenuItem>
      </Menu>}

      {props.isMulti && <div className={classes.previews}>
        {files.map(file => <><div aria-controls={`asset-menu-${file.id}`}   aria-haspopup="true"onClick={(e) => handleSelect(e, file)} className={classnames(classes.previewCard, {[classes.previewCardActive]: activeImage && activeImage.id === file.id})}><img src={`${file.preview}?preset=tiny`} className={classes.preview}/>

        </div>
          <Menu
            id={`asset-menu-${file.id}`}
            anchorEl={activeImage?.id === file.id ? anchorEl : null}
            keepMounted
            open={activeImage?.id === file.id ? Boolean(anchorEl) : false}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={() => handleShow(file)}>Редактировать</MenuItem>
            {(props.isMulti && props.showSetMain) && <MenuItem onClick={() => handleSetFeatured(file)}>Сделать главной</MenuItem>}
            <MenuItem onClick={() => handleDelete(file)}>Удалить</MenuItem>
          </Menu>
        </>)}
      </div>}
      <Box mt={1}>
      <Button variant="contained" size={'small'} color="primary" onClick={() => setModalOpened(true)}>
        {files.length > 0 ? (props.isMulti ? 'Добавить файл' : 'Изменить файл') : 'Добавить файл'}
      </Button>
      </Box>
      <ModalMedia fullWidth maxSize={maxSize} accept={accept} isShown={modalOpened} onClose={() => setModalOpened(false)} onSelect={handleAddAsset}/>
      </div>
    </Labeled>
    </div>
  )
}

MediaInput.defaultProps = {
  accept: ["image/jpeg", "image/png", 'image/svg+xml'],
  isMulti: false,
  showSetMain: false
}
