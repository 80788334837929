import PersonIcon from '@material-ui/icons/Person';
import MeditationCategoryList from './MeditationCategoryList'
import MeditationCategoryEdit from './MeditationCategoryEdit'
import MeditationCategoryCreate from './MeditationCategoryCreate'

export default {
  create: MeditationCategoryCreate,
  edit: MeditationCategoryEdit,
  list: MeditationCategoryList,
  icon: PersonIcon,
};