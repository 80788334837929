import PersonIcon from '@material-ui/icons/Person';
import FaqList from './FaqList'
import FaqEdit from './FaqEdit'
import FaqCreate from './FaqCreate'

export default {
  create: FaqCreate,
  edit: FaqEdit,
  list: FaqList,
  icon: PersonIcon,
};