import PersonIcon from '@material-ui/icons/Person';
import TreeList from './TreeList'
import TreeEdit from './TreeEdit'
import TreeCreate from './TreeCreate'

export default {
  create: TreeCreate,
  edit: TreeEdit,
  list: TreeList,
  icon: PersonIcon,
};