import PersonIcon from '@material-ui/icons/Person';
import DestinyNumberList from './DestinyNumberList'
import DestinyNumberEdit from './DestinyNumberEdit'
import DestinyNumberCreate from './DestinyNumberCreate'

export default {
  create: DestinyNumberCreate,
  edit: DestinyNumberEdit,
  list: DestinyNumberList,
  icon: PersonIcon,
};