import PersonIcon from '@material-ui/icons/Person';
import AdministratorList from './AdministratorList'
import AdministratorEdit from './AdministratorEdit'
import AdministratorCreate from './AdministratorCreate'

export default {
  create: AdministratorCreate,
  edit: AdministratorEdit,
  list: AdministratorList,
  icon: PersonIcon,
};