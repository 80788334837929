import PersonIcon from '@material-ui/icons/Person';
import MatrixList from 'src/resources/matrix/MatrixList'
import MatrixEdit from 'src/resources/matrix/MatrixEdit'
import MatrixCreate from 'src/resources/matrix/MatrixCreate'

export default {
  create: MatrixCreate,
  edit: MatrixEdit,
  list: MatrixList,
  icon: PersonIcon,
};
