import PersonIcon from '@material-ui/icons/Person';
import RelationshipList from './RelationshipList'
import RelationshipEdit from './RelationshipEdit'
import RelationshipCreate from './RelationshipCreate'

export default {
  create: RelationshipCreate,
  edit: RelationshipEdit,
  list: RelationshipList,
  icon: PersonIcon,
};