import * as React from 'react';
import {FC, useState} from 'react';
import { Box, Chip, useMediaQuery, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  CreateButton,
  ExportButton,
  Filter,
  FilterProps,
  InputProps,
  ListBase,
  ListProps,
  NumberInput,
  Pagination,
  ReferenceInput,
  SearchInput,
  SelectInput,
  SortButton,
  Title,
  TopToolbar,
  useListContext,
  useTranslate,
} from 'react-admin';

import GridList from './GridList';
import Aside from './Aside';
import {ModalCreateButton} from 'src/components/Modal/ModalButton'
import ModalMedia from 'src/components/ModalMedia'

const useQuickFilterStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}));

const QuickFilter: FC<InputProps> = ({ label }) => {
  const translate = useTranslate();
  const classes = useQuickFilterStyles();
  return <Chip className={classes.root} label={translate(label)} />;
};

export const ProductFilter: FC<Omit<FilterProps, 'children'>> = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <ReferenceInput
      source="category_id"
      reference="categories"
      sort={{ field: 'id', order: 'ASC' }}
    >
      <SelectInput source="name" />
    </ReferenceInput>
    <NumberInput source="width_gte" />
    <NumberInput source="width_lte" />
    <NumberInput source="height_gte" />
    <NumberInput source="height_lte" />
    <QuickFilter
      label="resources.products.fields.stock_lte"
      source="stock_lte"
      defaultValue={10}
    />
  </Filter>
);

const ListActions: FC<any> = ({ isSmall }) => (
  <TopToolbar>
    {isSmall && <ProductFilter context="button" />}
    <ModalCreateButton modal={<ModalMedia/>} />
  </TopToolbar>
);

const ProductList: FC<ListProps> = props => {
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  return (
    <ListBase
      perPage={20}
      {...props}
      sort={{field: 'id', order: 'DESC'}}
    >
      <ProductListView isSmall={isSmall} />
    </ListBase>
  );
};

const ProductListView: FC<{ isSmall: boolean }> = ({ isSmall }) => {
  const { defaultTitle } = useListContext();
  const [currentRecord, setCurrentRecord] = useState(null);
  const handleSelect = (record) => {
    console.log("handleRecord", record);
    setCurrentRecord(record)
  }
  return (
    <>
      <Title defaultTitle={defaultTitle} />
      <ListActions isSmall={isSmall} />
      {isSmall && (
        <Box m={1}>
          <ProductFilter context="form" />
        </Box>
      )}
      <Box display="flex">

        <Box >
          <GridList onSelect={handleSelect}/>
          <Pagination rowsPerPageOptions={[20, 30, 50, 100]} />
        </Box>
      </Box>
    </>
  );
};
export default ProductList;