import PersonIcon from '@material-ui/icons/Person';
import CalendarList from './CalendarList'
import CalendarEdit from './CalendarEdit'
import CalendarCreate from './CalendarCreate'

export default {
  create: CalendarCreate,
  edit: CalendarEdit,
  list: CalendarList,
  icon: PersonIcon,
};