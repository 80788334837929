import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ListProps,
  Datagrid,
  BooleanInput,
  DateField,
  SelectField,
  SelectInput,
  required,
  DateInput,
  ArrayInput,
  SimpleForm,
  ReferenceField,
  ReferenceInput, AutocompleteInput
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import {MoonPhaseList, PeriodList, PeriodListShort} from 'src/types'
import VisibilityField from 'src/components/fields/VisibilityField'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'moonPhase': 'Фаза луны',
      'startDate': 'Дата',
      'title': 'Название',
      'advice': 'Что можно',
      'body': 'Описание',
      'published': 'Опубликовано',
      'createdAt': 'Создано',

    },
    data:  posts.map(item => ({
      ...item,
      body: getTranslation(item)?.body,
      type: PeriodListShort.find(i => i.id === item.type)?.name || '',
      startDate:  formatCsvDate(item.startDate, true),
      moonPhase: MoonPhaseList.find(i => i.id === item.moonPhase)?.name || '',
      published: item.published ? 'Да' : '',
      advice: item.advice ? getTranslation(item.advice)?.body : '',
      createdAt:  formatCsvDate(item.createdAt),
      updatedAt:  formatCsvDate(item.updatedAt),
      publishedAt:  formatCsvDate(item.publishedAt),
    })),
    fileName: 'Calendar'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />

    <SelectInput source={'moonPhase'} label={'Фаза луны'} choices={MoonPhaseList} variant={'outlined'}  />
    <BooleanInput source={'published'} label={'Опубликовано'}/>
    <ReferenceInput label="Что можно" source={'advice'}
                    allowEmpty={false}  resettable={true} reference="tag"
                    variant={'outlined'} fullWidth={true}
                    filterToQuery={searchText => ({ ...(searchText ? { 'translations.body||$contL': searchText} : {}) })}
                    sort={{ field: 'translations.body', order: 'ASC' }}
                    filter={{type: 'calendar'}}
                    perPage={100}>
      <AutocompleteInput optionText={(record) => `${getTranslation(record)?.body}`}/>
    </ReferenceInput>
    <DateInput source="startDate" label={'Дата'} variant={'outlined'}/>
    <TextInput source="translations.title||$contL" label={'Поиск'}  variant={'outlined'}  alwaysOn={true} resettable={true}/>
    <TextInput source="translations.body||$contL" label={'Описание'}  variant={'outlined'}   resettable={true}/>
    <SelectInput source={'type'} label={'Период'} choices={PeriodListShort} variant={'outlined'}  />

  </Filter>
)

const CalendarList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Календарь"
      empty={<EmptyList title={'Нет Календаря'} description={'Вы можете добавить Каледарь'} buttonText={'Добавить Календарь'}/>}
    >
    <Datagrid rowClick={'edit'}>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <VisibilityField source={'published'} label={'Опубликовано'}/>
      <SelectField source={'type'} label={'Период'} choices={PeriodListShort}/>
      <SelectField source={'moonPhase'} label={'Фаза луны'} choices={MoonPhaseList}/>
      <DateField source="startDate" label={'Дата'}/>
      <ReferenceField label="Что можно" source="advice.id" reference="tag">
        <TransTextField source="body" />
      </ReferenceField>
      <TransTextField source="title" label={'Название'}/>
      <TransTextField source="body" label={'Описание'}/>
      <DateField source="createdAt" label={'Создано'}showTime={true} />
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default CalendarList;
