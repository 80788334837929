import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput, useDataProvider, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, ArrayInput, DateInput,
} from 'react-admin';

import {MediaInput} from 'src/components/inputs/MediaInput'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import {LangList, TypedAssetTypes} from 'src/types'


export const TypedAssetForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <BooleanInput source={'published'} label={'Опубликовано'}/>

      <SelectInput source="type" label={'Тип'}  variant={'outlined'} choices={TypedAssetTypes} validate={required()} />
      <MediaInput source={'asset'} label={'Файл'} accept={null}  validate={required()} />
      <ArrayInput source="translations" label={'Контент'}>
        <SimpleFormIterator>
          <SelectInput source="languageCode" label={'Язык'}  variant={'outlined'} choices={LangList} validate={required()} />
          <TextInput source="title" label={'Название'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>

    </SimpleForm>
  );
};
