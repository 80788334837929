import PersonIcon from '@material-ui/icons/Person';
import MeditationList from './MeditationList'
import MeditationEdit from './MeditationEdit'
import MeditationCreate from './MeditationCreate'

export default {
  create: MeditationCreate,
  edit: MeditationEdit,
  list: MeditationList,
  icon: PersonIcon,
};