import PersonIcon from '@material-ui/icons/Person';
import LifeCycleList from './LifeCycleList'
import LifeCycleEdit from './LifeCycleEdit'
import LifeCycleCreate from './LifeCycleCreate'

export default {
  create: LifeCycleCreate,
  edit: LifeCycleEdit,
  list: LifeCycleList,
  icon: PersonIcon,
};