import React, { FC, ReactElement, Component, Fragment, useState } from 'react';
import {
  makeStyles
} from '@material-ui/core';

import ContentCreate from '@material-ui/icons/Create';

import ContentAdd from '@material-ui/icons/Add';
import {
  Button
} from "react-admin";

const useStyles = makeStyles((theme) => ({
  button: {

  }
}));

interface Props {
  children?: ReactElement;
  label?: string;
  icon?: ReactElement,
  modal?: ReactElement
}


export const ModalButton: FC<Props> = (props) => {
  const {
    label,
    icon,
    children,
    modal,
  } = props;
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = (e) => {
    setShowDialog(true);
    console.log("SetShow");
    e.stopPropagation();
    e.preventDefault();
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Button type={'button'}  onClick={handleClick} className={classes.button} label={label}>
        {icon ? icon : (children ? children : null)}
      </Button>
      {showDialog && React.cloneElement(modal, {
        ...props,
        onClose: handleCloseClick,
        isShown: showDialog,
      })}
    </>
  );
}



export const ModalEditButton = (props) => <ModalButton label={'ra.action.edit'} icon={<ContentCreate/>}  {...props}/>
export const ModalCreateButton = (props) => <ModalButton label={'ra.action.create'} icon={<ContentAdd/>}  {...props}/>
