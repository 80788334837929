import PersonIcon from '@material-ui/icons/Person';
import LifeChangeList from './LifeChangeList'
import LifeChangeEdit from './LifeChangeEdit'
import LifeChangeCreate from './LifeChangeCreate'

export default {
  create: LifeChangeCreate,
  edit: LifeChangeEdit,
  list: LifeChangeList,
  icon: PersonIcon,
};