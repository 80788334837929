import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ListProps,
  Datagrid,
  TextField,
  BooleanInput, DateField, SimpleForm, DateInput, required
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {MediaInput} from 'src/components/inputs/MediaInput'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'digit': 'Дата',
      'count': 'Описание',
      'createdAt': 'Создано',

    },
    data:  posts.map(item => ({
      ...item,
      body: getTranslation(item)?.body,
      createdAt:  formatCsvDate(item.createdAt),
      updatedAt:  formatCsvDate(item.updatedAt),
    })),
    fileName: 'Matrix'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <TextInput source="translations.body||$contL" label={'Поиск'}  variant={'outlined'}  alwaysOn={true} resettable={true}/>

  </Filter>
)

const MatrixList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Аффирмации"
      empty={<EmptyList title={'Нет матриц'} description={'Вы можете добавить матрицу'} buttonText={'Добавить матрицу'}/>}
    >
    <Datagrid rowClick={'edit'}>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <TextField source={'digit'} label={'Цифра'} />
      <TextField source={'count'} label={'Кол-во'} />
      <TransTextField source="body" label={'Описание'}/>
      <DateField source="createdAt" label={'Создано'}showTime={true} />
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default MatrixList;
