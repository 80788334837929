import React, {useRef, useState} from 'react';
import {
  BooleanInput,
  required,
  TextInput, useDataProvider, useRefresh,
  ArrayInput, SimpleFormIterator, ReferenceInput, AutocompleteInput, SelectInput
} from "react-admin";

import ModalForm from 'src/components/ModalForm'
import CodeInput from 'src/components/inputs/CodeInput'
import {makeStyles} from '@material-ui/core/styles'
import {Box} from '@material-ui/core'
import {useFormState} from 'react-final-form'

const pretty = require('pretty');

const useStyles = makeStyles({
  content: {
    position: 'relative',
    alignItems: 'stretch',
    overflow: 'hidden',
    maxHeight: '100%',
    height: '100%',
  },
  form: {
    maxHeight: '100%',
    display: 'flex',
    height: '100%',
    '& > div': {
      overflow: 'hidden',
      height: '100%',
    }
  }

});
const Form = (props) => {
  const {record, html} = props;
  const [currentHtml, setCurrentHtml] = useState(html);
    const form = useFormState();
    console.log("form", form);
  const classes = useStyles()
  const handleChange = (value) => {
    console.log("HandleChange", value);
    setCurrentHtml(value);
  }
  return ( <Box className={classes.content} display={'flex'} flexDirection={'row'} width={'auto !important'}>
    <Box flex={'1'} height={'100%'}>
      <CodeInput source={'html'} label={'HTML'} onChange={handleChange}/>
    </Box>
    <Box className={'ql-editor'}  flex={'1'} overflow={'auto'} dangerouslySetInnerHTML={{__html: form.values.html}}/>
  </Box>)
}
export const ModalHtmlEditor = (props) => {
  const classes = useStyles()
  const {record, html} = props;

  const [currentHtml, setCurrentHtml] = useState(html);
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const viewRef = useRef(null);
  const handleSubmit = async data => {
    props.onSubmit(data);
    return true;
  };
  const handleChange = (value) => {
    console.log("HandleChange", value);
    setCurrentHtml(value);
  }
  console.log("SetHTML", pretty(html, {ocd: true}))
  return <ModalForm
    {...props}
    title={'Исходный код'}
    handleClose={props.onClose}
    save={handleSubmit}
    fullWidth={true}
    initialValues={{
      html: pretty(html, {ocd: true})
    }}
    contentClassName={classes.content}
    className={classes.form}

    record={record}
  >
  <Form html={html}/>


  </ModalForm>
}
