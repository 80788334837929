import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ListProps,
  Datagrid,
  NumberInput,
  TextField,
  BooleanInput, DateField, SimpleForm
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {DateInput} from 'src/components/inputs/DateInput'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'year': 'Год',
      'start': 'Старт',
      'end': 'Конец',
      'title': 'Название',
      'body': 'Описание',
      'published': 'Опубликовано',
      'createdAt': 'Создано',

    },
    data:  posts.map(item => ({
      ...item,
      body: getTranslation(item)?.body,
      published: item.published ? 'Да' : '',
      createdAt:  formatCsvDate(item.createdAt),
      updatedAt:  formatCsvDate(item.updatedAt),
      publishedAt:  formatCsvDate(item.publishedAt),
    })),
    fileName: 'Tree'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <NumberInput source="year" label={'Год'} />
    <DateInput source="start" label={'Старт'} />
    <DateInput source="end" label={'Конец'} />
    <TextInput source="translations.title||$contL" label={'Поиск'}  variant={'outlined'}  alwaysOn={true} resettable={true}/>
    <TextInput source="translations.body||$contL" label={'Описание'}  variant={'outlined'}  resettable={true}/>
    <BooleanInput source={'published'} label={'Опубликовано'}/>

  </Filter>
)

const TreeList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Дерево желаний"
      empty={<EmptyList title={'Нет деревьев желаний'} description={'Вы можете добавить дерево желаний'} buttonText={'Добавить дерево желаний'}/>}
    >
    <Datagrid rowClick={'edit'}>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <VisibilityField source={'published'} label={'Опубликовано'}/>
      <TextField source="year" label={'Год'}/>
      <DateField source="start" label={'Старт'}/>
      <DateField source="end" label={'Конец'}/>
      <TransTextField source="title" label={'Название'}/>
      <TransTextField source="body" label={'Описание'}/>
      <DateField source="createdAt" label={'Создано'}showTime={true} />
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default TreeList;
